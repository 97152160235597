<template>
  <div>
    <saveData
      :onClose="
        (id) => {
          onClose(id)
        }
      "
      v-if="saveKey"
      :key="saveKey"
      :save="save"
      :editID="editID"
    />
    <div ref="pagebuilder"><b-flix-loader></b-flix-loader></div>
  </div>
</template>
<script>
import loadPageBuilder from '@/components/pageBuilder/load.js'
export default {
  components: {
    saveData() {
      return import('@/components/targetPage/saveData')
    }
  },
  props: {
    onClose: Function,
    save: Array,
    editID: [String, Number]
  },
  mounted() {
    this.getData()
    this.loadPageBuilder()
  },
  computed: {},
  data() {
    return {
      saveKey: false
    }
  },
  methods: {
    getData() {
      this.save.forEach(
        function (v, k) {
          if (v.ID === this.editID) {
            this.edit = k
          }
        }.bind(this)
      )
    },
    setSave(blocks) {
      this.save[this.edit].layout = blocks
      this.$store.dispatch('setTargetPage', {
        user: this.$getUserVariables().user.md5_id,
        ID: this.editID,
        data: blocks,
        meta: {
          assistents: this.save[this.edit].assistents,
          method: this.save[this.edit].method
        },
        callback: () => {
          this.onClose()
        }
      })
    },
    loadPageBuilder() {
      loadPageBuilder({
        container: this.$refs.pagebuilder,
        language: this.$i18n.locale,
        blocks: [14, 1, 6],
        onClose: function () {
          this.onClose()
        }.bind(this),
        onSave: function (blocks) {
          this.setSave(blocks)
        }.bind(this),
        onChange: function (blocks) {},
        layout: this.save[this.edit].layout
        /* JSON.parse(this.layout) */
      }).run()
    }
  }
}
</script>
<style lang="sass" scoped></style>
